import React from "react"
import { Link } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"

import './404.scss'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404 Not Found" />
    <div className='main-404'>
      <h1>404 Not Found</h1>
    </div>
    <div className='back-to-home'><Link to='/'>Home</Link></div>
  </Layout>
)

export default NotFoundPage
